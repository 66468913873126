import React from "react";
import { skillsConfig } from "../config/skillsConfig";
import { AnimatedHeader } from "./ui/AnimateHeader";

export default function Skills() {
  return (
    <div className="container" id="skills">
      <AnimatedHeader
        className="skills__header"
        headerText={"Tech Stack"}
        data-aos="fade-up"
        data-aos-delay="300"
      />
      <div className="skills__button--wrapper">
        {skillsConfig.map((item, index) => (
          <div
            key={index}
            data-aos="fade-up"
            data-aos-delay="300"
            style={{ color: item.color }}
            className={`skill__buttons`}
          >
            {item.icon} <div className="skills__button">{item.text}</div>
          </div>
        ))}
      </div>
    </div>
  );
}
