import React from "react";
import GitHubIcon from "@mui/icons-material/GitHub";
import LaunchIcon from "@mui/icons-material/Launch";

export default function Project({
  image,
  onClick,
  title,
  liveLink,
  githubLink,
}) {
  return (
    <div className="project">
      <div className="project__description">
        <div className="project__title">{title}</div>
        <div className="project__info link__hover-effect" onClick={onClick}>
          Project Details
        </div>
        <div className="project__links">
          <a
            href={liveLink}
            target="_blank"
            rel="noreferrer"
            className="project__link"
          >
            <LaunchIcon />
          </a>
          <a
            href={githubLink}
            target="_blank"
            rel="noreferrer"
            className="project__link"
          >
            <GitHubIcon />
          </a>
        </div>
      </div>
      <img src={image} alt="" className="project__img" loading="lazy" />
    </div>
  );
}
