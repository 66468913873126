import { OrbitControls, Stars } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";

function CanvasBg() {
  return (
    <div className="canvasbg">
      <Canvas>
        <Stars />
        <OrbitControls
          panSpeed={0.4}
          autoRotate
          autoRotateSpeed={0.4}
          enableZoom={false}
        />
        <ambientLight intensity={1} />
      </Canvas>
    </div>
  );
}

export default CanvasBg;
