import React from "react";
import EmailIcon from "@mui/icons-material/Email";

export default function MailIcon() {
  return (
    <a href="#contact" className="mail-icon">
      <EmailIcon />
    </a>
  );
}
