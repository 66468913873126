import MangaTop from "../assets/project-images/MangaKyu/manga-top.png";
import MangaCover from "../assets/project-images/MangaKyu/manga-cover.jpg";

import EmpCover from "../assets/project-images/Emporium/emp-cover.jpg";
import EmpTop from "../assets/project-images/Emporium/emp-top.png";

import TwitCover from "../assets/project-images/Twitter/twit-cover.jpg";
import TwitTop from "../assets/project-images/Twitter/twit-top.png";

import SpotifyCover from "../assets/project-images/Spotify/spotify-cover.jpg";
import SpotifyTop from "../assets/project-images/Spotify/spotify-top.jpg";

const projectsConfig = [
  {
    title: "MangaKyu",
    type: "Web App: React, HTML, CSS, JavaScript",
    description:
      "Fully responsive manga database app that allows the user to search, filter, and view manga info. Implemented skeleton loading states to improve user experience. The data is fetched from the Jikan v4 API.",
    liveLink: "https://mangakyu.vercel.app/",
    githubLink: "https://github.com/JamKyu/MangaKyu",
    coverImage: MangaCover,
    topImage: MangaTop,
  },
  {
    title: "Emporium",
    type: "E-Commerce Web App: React, HTML, CSS, JavaScript",
    description:
      "Dungeons & Dragons themed e-commerce app that allows the user to view and sort magic items. Implemented the ability to add and remove items from the cart, while also utilizing skeleton loading states to improve the user's loading experience.",
    liveLink: "https://k-emporium.vercel.app/",
    githubLink: "https://github.com/JamKyu/EmporiumE-CommerceReact",
    coverImage: EmpCover,
    topImage: EmpTop,
  },
  {
    title: "Social Media App",
    type: "Twitter Clone: React, Next.js, Tailwind CSS, Typescript, Sanity CMS",
    description:
      "Social media app that allows the user to sign in using Twitter Authentication and post tweets to the feed with an option to upload images. Users can also view and leave replies to the posted tweets. User generated data is stored and managed by Sanity CMS.",
    liveLink: "https://jamkyu-twitter.vercel.app/",
    githubLink: "https://github.com/JamKyu/TwitterCloneReact",
    coverImage: TwitCover,
    topImage: TwitTop,
  },
  {
    title: "Spotify Remote Web Player",
    type: "Spotify Clone: React, Next.js, Tailwind CSS, Spotify API, NextAuth, Middleware, Recoil",
    description:
      "Spotify Remote Web Player Clone built using Next.js that allows user to login to Spotify account and view user's playlists and play tracks remotely. NOTE: User must have Spotify Premium account & local player to utilize Remote Player due to Spotify API restraints.",
    liveLink: "https://kyu-spotify.vercel.app/",
    githubLink: "https://github.com/JamKyu/SpotifyRemoteWebPlayer",
    coverImage: SpotifyCover,
    topImage: SpotifyTop,
  },
];

export default projectsConfig;
