import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Nav from "../components/Nav";
import Landing from "../components/Landing";
import MailIcon from "../components/ui/MailIcon";
import CanvasBg from "../components/ui/CanvasBg";
import About from "../components/About";
import Skills from "../components/Skills";
import Projects from "../components/Projects";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import Loader from "../components/Loader";
import Cursor from "../components/ui/Cursor";

function Home() {
  const initRubberBandAnimation = () => {
    document.querySelectorAll(".rubberBand").forEach((elem) => {
      elem.addEventListener("mouseover", () => {
        elem.classList.add("animated");
      });
      elem.onanimationend = () => {
        elem.classList.remove("animated");
      };
    });
  };

  useEffect(() => {
    document.body.classList += " overflow__body";
    initRubberBandAnimation();
    AOS.init({ duration: 1000 });
  }, []);

  const [rotateSpeed, setRotateSpeed] = useState(3);

  return (
    <>
      <Cursor />
      <CanvasBg />
      <div className="main__container">
        <Nav />
        <Landing />
        <About />
        <Skills />
        <Projects />
        <Contact rotateSpeed={rotateSpeed} setRotateSpeed={setRotateSpeed} />
        <Footer />
      </div>
      <MailIcon />
      <Loader />
    </>
  );
}

export default Home;
