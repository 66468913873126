import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import Kira from "../assets/kira.JPG";

export default function Cat({ isOpen, handleClose }) {
  if (isOpen)
    return (
      <div className="footer__modal">
        <div className="footer__modal--close" onClick={() => handleClose()}>
          <CloseIcon />
        </div>
        <figure className="footer__modal--img--wrapper">
          <img
            src={Kira}
            alt=""
            className="footer__modal--img"
            loading="lazy"
          />
        </figure>
        <h3 className="footer__modal--title">Kira</h3>
      </div>
    );
}
