import React, { useEffect, useState } from "react";
import Logo from "../assets/logo.svg";

const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

export default function Loader() {
  const [loaded, setLoaded] = useState(false);
  const [fadeOutLoader, setFadeOutLoader] = useState(false);
  const [removeLoader, setRemoveLoader] = useState(false);

  async function load() {
    await sleep(2000);
    setLoaded(true);
    await sleep(1000);
    setFadeOutLoader(true);
    document.body.classList.remove("overflow__body");
    await sleep(1000);
    setRemoveLoader(true);
  }

  useEffect(() => {
    load();
  }, []);

  return (
    <div
      id="preload"
      className={`${fadeOutLoader ? "fade-out__loader" : ""} ${
        removeLoader ? "remove__loader" : ""
      }`}
    >
      <div className={`loader ${loaded ? "loader__fade" : ""}`}>
        <img src={Logo} className="loader__logo" alt="" />
        <div className="lds-grid">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
}
