import React, { useState } from "react";
import {
  FaArrowCircleUp,
  FaGithub,
  FaLinkedin,
  FaFilePdf,
} from "react-icons/fa";
import Waves from "../assets/waves/footer-waves.svg";
import Resume from "../assets/JamesKyu-Resume.pdf";
import Logo from "../assets/logo.svg";
import Cat from "./Cat";

const Footer = () => {
  const [isOpen, setOpen] = useState(false);
  const [opacity, setOpacity] = useState(1);

  const handleOpen = () => {
    setOpen(true);
    setOpacity(0);
  };

  const handleClose = () => {
    setOpen(false);
    setOpacity(1);
  };

  return (
    <footer id="footer">
      <img src={Waves} alt="" className="footer-waves" />
      <div className="container">
        <div className="row footer__row" style={{ opacity }}>
          <a href="#landing" className="footer__anchor">
            <figure>
              <img
                src={Logo}
                className="footer__logo--img"
                alt="Footer Logo"
              />
            </figure>
            <span className="footer__logo--popper">
              Top {""}
              <FaArrowCircleUp aria-hidden="true" />
            </span>
          </a>
          <div className="footer__social--list">
            <a
              href="https://github.com/JamKyu"
              target="_blank"
              rel="noreferrer"
              className="footer__social--link link__hover-effect"
            >
              <FaGithub />
              {""} GitHub
            </a>
            <a
              href="https://www.linkedin.com/in/james-kyu/"
              target="_blank"
              rel="noreferrer"
              className="footer__social--link link__hover-effect"
            >
              <FaLinkedin />
              {""} LinkedIn
            </a>
            <a
              href={Resume}
              target="_blank"
              rel="noreferrer"
              className="footer__social--link link__hover-effect"
            >
              <FaFilePdf /> {""} Resume
            </a>
          </div>
          <div className="footer__email">jamkyu@proton.me</div>
          <div className="footer__icon--container">
            <button onClick={() => handleOpen()} className="footer__icon">
              Click for Cat
            </button>
          </div>
          <div className="footer__copyright">Made by James Kyu - 2022</div>
        </div>
        <Cat isOpen={isOpen} handleClose={handleClose} />
      </div>
    </footer>
  );
};

export default Footer;
