import { GitHub, LinkedIn, Description } from "@mui/icons-material/";
import React from "react";
import { AnimatedHeader } from "./ui/AnimateHeader";
import Resume from "../assets/JamesKyu-Resume.pdf";

function Landing() {
  return (
    <>
      <div id="landing" className="container" data-aos="fade-in">
        <div className="landing__text-container">
          <a
            href="https://www.youtube.com/watch?v=eaEMSKzqGAg"
            target="_blank"
            rel="noreferrer"
          >
            <AnimatedHeader
              headerText={"Hello there,"}
              className="landing__header hello-there"
            />
          </a>
          <AnimatedHeader
            headerText={"I'm James Kyu."}
            className="landing__sub-header"
          />
          <p className="landing__text-header">
            Frontend Developer in Nanuet, New York
          </p>
          <hr />
          <div className="landing__icons-container">
            <a
              href="https://github.com/JamKyu"
              target="_blank"
              className="landing__icon"
              rel="noreferrer"
            >
              <GitHub />
            </a>
            <a
              href="https://www.linkedin.com/in/james-kyu/"
              target="_blank"
              className="landing__icon"
              rel="noreferrer"
            >
              <LinkedIn />
            </a>
            <a
              href={Resume}
              target="_blank"
              className="landing__icon"
              rel="noreferrer"
            >
              <Description />
            </a>
          </div>
          <a href="#contact" className="landing__button">
            Contact Me
          </a>
        </div>
        <a href="#about" className="scroll">
          <div className="scroll__icon click"></div>
        </a>
      </div>
    </>
  );
}

export default Landing;
