import React, { useState } from "react";

function Nav() {
  const [openNav, setNavOpen] = useState(false);

  const handleNav = () => {
    setNavOpen(!openNav);
  };

  return (
    <>
      <div
        className={`nav__burger ${openNav ? "open" : ""}`}
        onClick={handleNav}
      >
        <div className="nav__burger-bar"></div>
        <div className="nav__burger-bar"></div>
        <div className="nav__burger-bar"></div>
      </div>
      <nav className={openNav ? "open" : ""}>
        <div className="nav__container">
          <div className="nav__list">
            <a href="#about" onClick={handleNav}>
              About
            </a>
            <a href="#projects" onClick={handleNav}>
              Projects
            </a>
            <a href="#contact" onClick={handleNav}>
              Contact
            </a>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Nav;
