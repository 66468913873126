import emailjs from "@emailjs/browser";
import { useRef, useState, useMemo, useEffect } from "react";
import { FaSpinner as LoadIcon } from "react-icons/fa";
import { AnimatedHeader } from "./ui/AnimateHeader";
import { OrbitControls, Text } from "@react-three/drei";
import { Canvas, useFrame } from "@react-three/fiber";
import * as THREE from "three";
import Reaptcha from "reaptcha";

export default function Contact({ rotateSpeed, setRotateSpeed }) {
  const [sending, setSending] = useState(false);
  const [headerText, setHeaderText] = useState({
    text: "Contact me:",
    modifier: "",
  });
  const form = useRef();

  const [verified, setVerified] = useState(false);
  const captchaRef = useRef(null);

  const onVerify = () => {
    setVerified(true);
  };

  const onExpire = () => {
    setVerified(false);
  };

  const sendEmail = (e) => {
    e.preventDefault();
    if (!verified) {
      return;
    } else {
      setSending(true);
      setRotateSpeed(60);
      emailjs
        .sendForm(
          `${process.env.REACT_APP_SERVICE_ID}`,
          `${process.env.REACT_APP_TEMPLATE_ID}`,
          form.current,
          `${process.env.REACT_APP_USER_ID}`
        )
        .then(
          (res) => {
            console.log(res);
            setRotateSpeed(3);
            form.current.reset();
            setHeaderText({ text: "Message sent!", modifier: "-success" });
            setSending(false);
          },
          (err) => {
            console.log(err.text);
            setRotateSpeed(3);
            setSending(false);
            setHeaderText({
              text: "Oops :(",
              modifier: "-error",
            });
            alert(
              "Email service is temporarily unavailable. I would love to hear from you, so please contact me directly at: jamkyu@proton.me"
            );
          }
        )
        .catch((e) => {
          console.log(e);
          setRotateSpeed(3);
          setSending(false);
          setHeaderText({ text: "Oops :(", modifier: "-error" });
          alert(
            "Email service is temporarily unavailable. I would love to hear from you, so please contact me directly at: jamkyu@proton.me"
          );
        });
    }
  };

  const skill = [
    "HTML",
    "CSS",
    "JavaScript",
    "React",
    "TypeScript",
    "GitHub",
    "NPM",
    "Yarn",
    "ThreeJS",
    "Redux",
    "Hooks",
    "BEM",
    "Firebase",
    "Flexbox",
    "ES6/ES7",
    "Git",
    "JSON",
    "Tailwind",
  ];

  function randomSkill() {
    return skill[Math.floor(Math.random() * skill.length)];
  }

  function Word({ children, ...props }) {
    const color = new THREE.Color();
    const fontProps = {
      fontSize: 1.2,
      letterSpacing: -0.05,
      lineHeight: 1,
      "material-toneMapped": false,
    };
    const ref = useRef();
    const [hovered, setHovered] = useState(false);
    const over = (e) => {
      e.stopPropagation();
      setHovered(true);
    };
    const out = () => setHovered(false);
    useEffect(() => {
      if (hovered) document.body.style.cursor = "pointer";
      return () => (document.body.style.cursor = "auto");
    }, [hovered]);
    useFrame(({ camera }) => {
      ref.current.quaternion.copy(camera.quaternion);

      ref.current.material.color.lerp(
        color.set(hovered ? "#4E1E23" : "#EEDDCC"),
        0.1
      );
    });
    return (
      <Text
        ref={ref}
        onPointerOver={over}
        onPointerOut={out}
        {...props}
        {...fontProps}
        children={children}
      />
    );
  }

  function Cloud({ count = 4, radius = 20 }) {
    // Create a count x count random words with spherical distribution
    const words = useMemo(() => {
      const temp = [];
      const spherical = new THREE.Spherical();
      const phiSpan = Math.PI / (count + 1);
      const thetaSpan = (Math.PI * 2) / count;
      for (let i = 1; i < count + 1; i++)
        for (let j = 0; j < count; j++)
          temp.push([
            new THREE.Vector3().setFromSpherical(
              spherical.set(radius, phiSpan * i, thetaSpan * j)
            ),
            randomSkill(),
          ]);
      return temp;
    }, [count, radius]);
    return words.map(([pos, word], index) => (
      <Word key={index} position={pos} children={word} />
    ));
  }

  return (
    <div className="contact container" id="contact">
      <div className="row contact__sub-container">
        <form
          ref={form}
          className="contact__form"
          onSubmit={sendEmail}
          data-aos="fade-right"
          data-aos-delay="500"
        >
          <AnimatedHeader
            className={`contact__header-text contact__header-text-${headerText.modifier}`}
            headerText={headerText.text}
          />
          <input type="text" placeholder="Name" required name="user_name" />
          <input type="email" placeholder="Email" required name="user_email" />
          <textarea
            name="user_message"
            type="text"
            id=""
            cols="30"
            rows="10"
            style={{ resize: "none" }}
            placeholder="Message"
            required
          ></textarea>
          <Reaptcha
            sitekey={process.env.REACT_APP_SITE_KEY}
            ref={captchaRef}
            theme="dark"
            onVerify={onVerify}
            onExpire={onExpire}
          />
          <button
            type="submit"
            className="contact__form-btn"
            style={{ pointerEvents: sending ? "pointer" : "default" }}
          >
            {sending ? (
              <span>
                <LoadIcon
                  alt="loading-spinner"
                  width="24px"
                  height="24px"
                  className="contact__loading-spinner"
                />
              </span>
            ) : (
              "Send Message"
            )}
          </button>
        </form>
        <div
          className="contact__canvas hide-mobile"
          data-aos="fade-left"
          data-aos-delay="500"
        >
          <Canvas dpr={[1, 2]} camera={{ position: [0, 0, 40], fov: 90 }}>
            <fog attach="fog" args={["#AE903C", 0, 80]} />
            <Cloud count={6} radius={14} />
            <OrbitControls
              autoRotate
              autoRotateSpeed={rotateSpeed ? rotateSpeed : 3}
              enableZoom={false}
            />
          </Canvas>
        </div>
      </div>
    </div>
  );
}
