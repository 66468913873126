import React from "react";
import Waves from "../assets/waves/about-waves.svg";
import Me from "../assets/me.png";

export default function About() {
  return (
    <div className="container about">
      <img src={Waves} id="waves" alt="" />
      <div
        className="about__wrapper"
        id="about"
        data-aos="fade-down"
        data-aos-delay="50"
      >
        <div className="about__left">
          <figure className="about__img--wrapper">
            <img src={Me} alt="" className="about__img" />
          </figure>
          Freelance <b className="text-secondary">front-end web developer</b>{" "}
          with a passion for building web applications that provide responsive
          and performant user experiences.
        </div>
        <div className="about__right">
          <h3 className="about__title">About Me:</h3>
          I'm an Enrolled Agent and{" "}
          <b className="text-secondary">front-end developer</b>.
          <br />
          <br />
          As an Enrolled Agent, I love helping people understand their personal
          taxes by communicating complex topics in an accessible format. This
          led me to discover that I have a strong passion for web development
          and creating{" "}
          <b className="text-secondary">
            responsive and accessible user experiences
          </b>
          .
          <br />
          <br />
          Through my work experience, I developed the ability to both work
          independently and collaborate in a team environment to resolve complex
          problems on a daily basis. I am seeking a full-time role where I can
          help a business offer the{" "}
          <b className="text-secondary">best experience for their clients</b>.
        </div>
      </div>
    </div>
  );
}
