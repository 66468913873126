import "./style.css";
import Home from "./pages/Home";
import { Suspense } from "react";

function App() {
  return (
    <div className="app">
      <Suspense fallback={<div></div>}>
        <Home />
      </Suspense>
    </div>
  );
}

console.log(`
#     #                          
#     # ####### #      #      ######
#     # #       #      #      #    #
####### #####   #      #      #    #
#     # #       #      #      #    #
#     # #       #      #      #    #
#     # ####### ###### ###### ######
####### 
   #    #    # ###### #####  ######
   #    #    # #      #    # #
   #    ###### #####  #    # #####
   #    #    # #      #####  #
   #    #    # #      #   #  #
   #    #    # ###### #    # ######`);
console.log("Thank you for visiting!");
console.log("This website was made by James Kyu.");

export default App;
