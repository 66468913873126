import GitHubIcon from "@mui/icons-material/GitHub";
import LaunchIcon from "@mui/icons-material/Launch";
import CloseIcon from "@mui/icons-material/Close";

function Modal({ isOpen, project, closeHandler }) {
  if (isOpen) {
    return (
      <div className="modal__container">
        <div className="modal__close" onClick={() => closeHandler()}>
          <CloseIcon />
        </div>
        <div className="modal__top--background">
          <div className="modal__top">
            <div className="modal__top--image">
              <img src={project.topImage} alt="" loading="lazy" />
            </div>
          </div>

          <div className="modal__project--background">
            <div className="modal__project--wrapper">
              <div className="modal__title">{project.title}</div>
              <div className="modal__type">{project.type}</div>
              <div className="modal__description">{project.description}</div>
              <div className="modal__view--button-wrapper ">
                <a
                  href={project.liveLink}
                  target="_blank"
                  rel="noreferrer"
                  className="modal__view-online--button link__hover-effect"
                >
                  View Online
                  <LaunchIcon />
                </a>
                <a
                  href={project.githubLink}
                  target="_blank"
                  rel="noreferrer"
                  className="modal__view-online--button link__hover-effect"
                >
                  View Github
                  <GitHubIcon />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}

export default Modal;
